<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <!-- <h2 class="brand-text text-primary ml-1">BoxStop</h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <div>
          <b-overlay
            :show="showRegisterOverlay"
            rounded="sm"
            variant="dark"
          >
            <div>
              <b-col
                sm="8"
                md="6"
                lg="12"
                class="px-xl-2 mx-auto"
              >
                <b-card-title
                  title-tag="h2"
                  class="font-weight-bold mb-1"
                >
                  Adventure starts here 🚀
                </b-card-title>
                <b-card-text class="mb-2">
                  Register to start managing your Riders!
                </b-card-text>

                <b-row v-if="hasError">
                  <b-col cols="12 text-left">
                    <div
                      class="alert alert-danger alert-dismissible"
                      role="alert"
                    >
                      <h4 class="alert-heading">
                        {{ errors.message }}
                      </h4>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                        @click="hasError = !hasError"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <div class="alert-body">
                        <div
                          v-for="(item, index) in errors.data"
                          :key="index"
                        >
                          * <small>{{ item.toString() }}</small>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <!-- form -->
                <validation-observer ref="registerForm">
                  <b-form class="auth-register-form mt-2">
                    <!-- username -->
                    <b-form-group
                      label="Name"
                      label-for="register-username"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          id="register-username"
                          v-model="userName"
                          name="register-username"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Ben Onabe"
                          size="sm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- business name -->
                    <b-form-group
                      label="Business Name"
                      label-for="register-business-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Business name"
                        rules="required"
                      >
                        <b-form-input
                          id="register-business-name"
                          v-model="userBusinessName"
                          name="register-business-name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Sample Logistics"
                          size="sm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- phone -->
                    <b-form-group
                      label="Phone"
                      label-for="register-phone"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Phone"
                        rules="required"
                      >
                        <b-form-input
                          id="register-phone"
                          v-model="userPhone"
                          name="register-phone"
                          :state="errors.length > 0 ? false : null"
                          placeholder="07030000000"
                          size="sm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- email -->
                    <b-form-group
                      label="Email"
                      label-for="register-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="register-email"
                          v-model="userEmail"
                          name="register-email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="ben@example.com"
                          size="sm"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- password -->
                    <b-form-group
                      label="New Password"
                      label-for="reset-password-new"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        vid="Password"
                        rules="required|password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="reset-password-new"
                            v-model="password"
                            :type="password1FieldType"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="reset-password-new"
                            placeholder="············"
                            size="sm"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="password1ToggleIcon"
                              @click="togglePassword1Visibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- confirm password -->
                    <b-form-group
                      label-for="reset-password-confirm"
                      label="Confirm Password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Confirm Password"
                        rules="required|confirmed:Password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                          <b-form-input
                            id="reset-password-confirm"
                            v-model="cPassword"
                            :type="password2FieldType"
                            class="form-control-merge"
                            :state="errors.length > 0 ? false : null"
                            name="reset-password-confirm"
                            placeholder="············"
                            size="sm"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="password2ToggleIcon"
                              @click="togglePassword2Visibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group>
                      <b-form-checkbox
                        id="register-privacy-policy"
                        v-model="status"
                        name="checkbox-1"
                        required
                      >
                        I agree to
                        <b-link
                          v-b-modal.modal-terms
                        >privacy policy & terms</b-link>
                      </b-form-checkbox>
                    </b-form-group>

                    <b-button
                      variant="primary"
                      block
                      type="submit"
                      @click.prevent="validationForm"
                    >
                      Sign up
                    </b-button>
                  </b-form>
                </validation-observer>

                <p class="text-center mt-2">
                  <span>Already have an account?</span>
                  <b-link :to="{ name: 'login' }">
                    <span>&nbsp;Sign in instead</span>
                  </b-link>
                </p>

                <!-- divider -->
                <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
              </b-col>
            </div>
          </b-overlay>
        </div>
      </b-col>
      <!-- /Register-->

      <!--  TERMS AND CONDITIONS MODAL -->
      <b-modal
        id="modal-terms"
        ref="modal-terms"
        centered
        title=""
        hide-footer
        size="lg"
      >
        <div>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong><span
              style="font-size: 16px; line-height: 150%"
            >DISPATCH PARTNERSHIP AGREEMENT.</span></strong>
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            THIS DISPATCH PARTNERSHIP AGREEMENT (<strong>&quot;PARTNERSHIP AGREEMENT&quot;)&nbsp;</strong>is a legally binding Agreement &nbsp;Between &nbsp;You our Partner
            (&ldquo;<strong>Boxstop Partner/ Partner&rdquo;)&nbsp;</strong>is<strong>&nbsp;</strong>an individual, business or Company engaged
            in the activities of dispatch-hailing and or delivery and or
            logistics for profit; and Time Cybertech &amp; Resources Ltd
            (<strong>&ldquo;TCRL&rdquo;</strong>) owners and producers of the
            Boxstop Mobile Application (the &ldquo;<strong>Boxstop Application&rdquo;</strong>)
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            This Agreement is entered into to govern the partnership
            relationship between you and TCRL and your use of the Boxstop mobile
            application<strong>&nbsp;(the &ldquo;Boxstop Application&rdquo;)</strong>.
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <br>
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>BACKGROUND:</strong>
          </p>
          <ol
            start="1"
            style="list-style-type: lower-alpha"
          >
            <li>
              <span
                style="font-family: Calibri"
              >TCRL is an innovative technology startup innovating the package
                delivery industry with its robust and seamless software platform
                that connects users to thousands of different delivery services
                to complete their package delivery in convenient and
                business-efficient ways.</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >In view of TCRL&rsquo;s mission it has developed an on-demand,
                innovative and full-service dispatch-hailing technology
                platform- (&ldquo;<strong>Boxstop Application&rdquo;</strong>)
                whose features are included in the
                <strong>Product Brochure</strong> (included in this Agreement
                as<strong>&nbsp;Addendum 1</strong>).</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >TCRL has recognized partnership engagements as an essential
                avenue to deploy the Boxstop Application to the dispatch
                delivery industry and has requested the partnership of
                dispatch-hailing companies, agents, riders and service providers
                to be Boxstop Partners and adopt this innovative technology
                platform in favour of their business needs and models.</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >The Boxstop Partner is a company and or business or private
                individual engaged in the business activity of providing
                dispatch-hailing and or logistics services to its diverse
                customer base in Nigeria.</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >The Boxstop Partner recognizes the innovative and business
                benefits of partnering with TSRL and adopting the Boxstop
                Application to meet the growing needs of the users and customers
                of its dispatch-hailing and logistics services across
                Nigeria.</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >The Boxstop Partner and TCRL have declared their mutual
                intentions to enter into a partnership and have entered into
                this Agreement to set out their intentions and be bound by its
                Terms and obligations.</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >By clicking the &ldquo;<strong>Accept</strong>&rdquo; button
                below or using the Boxstop Application, Boxstop Partner
                acknowledges that you have read, fully understand, agree to, and
                will be bound by this Agreement. If you do not agree to be bound
                by this Agreement, you will not be able to use the Boxstop
                Application and you should exit the Boxstop Application
                immediately.</span>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>IT IS HEREBY AGREED AS FOLLOWS:</strong>
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol style="margin-bottom: 0in; list-style-type: decimal">
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <strong><span
                  style="font-family: Calibri"
                >DEFINITIONS.</span></strong>
              </li>
            </ol>
          </div>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>&ldquo;Agreement&rdquo;</strong> means this agreement
            together with all its amendments, which applies to the use by the
            Customer of the Boxstop Application and other related Products and
            Services as entered into between TCRL and the User and updated from
            time to time. &nbsp;&nbsp;
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &ldquo;<strong>Authorized Partner</strong>&rdquo; means any of TCRL
            &lsquo;s authorized distributors, resellers or business partners.
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &ldquo;<strong>Documentation</strong>&rdquo; means all user manuals,
            operating manuals, technical manuals, handbooks, installation
            guides, design documents and any other instructions, specifications,
            documents or materials, in any form or media, that describe the
            functionality, structure, installation, testing, operation, use,
            maintenance, support, technical or other components, features or
            requirements of the Boxstop Application.
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &ldquo;<strong>Free Software Component</strong>&rdquo; means free or
            open source components, including Open Source Software.
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &ldquo;<strong>Intellectual Property Rights</strong>&rdquo; means
            any and all intellectual property rights in any part of the world,
            arising under statutory or common law or by agreement and whether or
            not perfected, registered or unregistered, now existing or hereafter
            filed, issued, or acquired, and any renewals, extensions and other
            government issued indicia of ownership thereof, including, but not
            limited to, rights comprising or relating to:
          </p>
          <ol
            start="1"
            style="list-style-type: lower-alpha"
          >
            <li>
              patents, patent disclosures and inventions (whether patentable or
              not);
            </li>
            <li>
              rights associated with works of authorship including copyrights
              and copyrightable works (including, but not limited to, computer
              programs), copyright registrations and application for copyright
              registration, &ldquo;moral&rdquo; rights and mask work rights (all
              &ldquo;copyrights&rdquo;);
            </li>
            <li>
              rights relating to the protection of trade secrets, know-how and
              other Confidential Information;
            </li>
            <li>
              trademarks, trade dress, trade names, logos and service marks,
              together with the goodwill or business symbolized by or associated
              therewith (&ldquo;Trademarks&rdquo;);
            </li>
            <li>domain names, web addresses and social media identifiers;</li>
            <li>
              any registrations or applications for registration for any of the
              foregoing, including any provisionals, divisions, continuations,
              continuations-in-part, renewals, reissuances, re-examinations and
              extensions (as applicable); and (g) analogous rights to those set
              forth above.
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>&ldquo;Professional Services&rdquo;</strong> means
            collectively the training, consulting, installation, migration
            and/or deployment services purchased by the User sequel to the
            Agreement.
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>&ldquo;Support Services</strong>&rdquo; means the provision
            of technical support services for the Products purchased by the User
            either from TCRL or an Authorized Partner subject to the support
            level and support options enjoyed by the User pursuant to this
            Agreement.
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>&ldquo;Update&rdquo;</strong> means any updates, upgrades,
            bug fixes, patches, and other error corrections to the Boxstop
            Application that TCRL provides to Users when subscribed to our
            continuing Support Services.
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>&nbsp;</strong>
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol style="margin-bottom: 0in; list-style-type: undefined">
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <strong><span style="font-family: Calibri">PARTNERSHIP</span></strong>
              </li>
            </ol>
          </div>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            Subject to the terms of this Agreement, TCRL and the Boxstop Partner
            hereby agree to partner together, for the mutual purpose of
            deploying the Boxstop Application and its related products and
            services to automate dispatch-hailing services and logistics
            services for customers of the Boxstop Partner.
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol style="margin-bottom: 0in; list-style-type: undefined">
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <strong><span
                  style="font-family: Calibri"
                >TCRL PARTNERSHIP OBLIGATIONS.</span></strong>
              </li>
            </ol>
          </div>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>&nbsp;</strong>
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol style="margin-bottom: 0in; list-style-type: undefined">
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <span
                  style="font-family: Calibri"
                >Subject to the terms and obligations set out in this
                  Agreement, TCRL hereby grants to the Boxstop Partner a
                  non-exclusive license to download, install, and use Boxstop
                  application (together with any updates, modifications, or new
                  versions, the &ldquo;<strong>Licensed Application</strong>&rdquo;) for the purpose of automating and managing its
                  dispatch-hailing and logistics services and operations
                  efficiently to its customers.</span>
              </li>
            </ol>
          </div>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol style="margin-bottom: 0in; list-style-type: undefined">
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <span
                  style="font-family: Calibri"
                >TCRL hereby gives the Boxstop Partner unhindered access to
                  its Licensed Application for the benefits and &nbsp;purpose
                  of:</span>
              </li>
            </ol>
          </div>
          <ol
            start="1"
            style="list-style-type: lower-alpha; margin-left: 0.25in"
          >
            <li>
              <span
                style="font-family: Calibri"
              >Automating internal operations for the Boxstop Partner&rsquo;s
                business,</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >Simplifying and automating the Boxstop Partner&rsquo;s
                dispatch-hailing and logistics operations and services to its
                customers,</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >&nbsp;Maximizing sales of customer prospects by linking the
                Boxstop Partner to more customers,</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >providing and deploying an automated payment infrastructure
                between the Partner and its customers, and granting access to
                direct bank credit features for all earnings and profits
                received through the application.</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >providing ease of communication and confirmation of orders
                between the Partners, their customers and Dispatch
                Riders,&nbsp;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >providing an automated administrative dashboard and monitoring
                automated set-up for monitoring all aspects and functions
                related to the Partners dispatch-hailing services and
                operations,</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >Accessing automated management and internal operations tools
                such as accounting and bookkeeping and other financial
                frameworks for efficient management and deployment of financial
                functionalities &nbsp;to its end users and
                customers,&nbsp;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >Granting access to monitoring interface for dispatch riders
                onboarding and communication from time to time and</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >Grant access to further updates and additions, relating to
                enhanced functionalities and features of the Boxstop Application
                as will be deployed from time to time.</span>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.75in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
              text-indent: -31.5pt;
            "
          >
            &nbsp;
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol style="margin-bottom: 0in; list-style-type: undefined">
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <span
                  style="font-family: Calibri"
                >TCRL hereby grants to the Partner, control and use of the
                  Boxstop application on all enabled devices (Android and Apple
                  devices) for the purpose of administering all the benefits and
                  features provided under Clause 3.2 of this Agreement.</span>
              </li>
            </ol>
          </div>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined"
          >
            <li>
              <strong><span
                style="font-family: Calibri"
              >PARTNERSHIP &nbsp;OBLIGATIONS</span></strong>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  <span
                    style="font-family: Calibri"
                  >The partner hereby agrees that the license granted for the
                    use of the Licensed Application by TCRL is limited to a
                    non-exclusive, non-transferable license to download,
                    install, and use the Licensed Application on any device
                    allowed under any applicable terms and conditions of the
                    application marketplace used to purchase the Licensed
                    Application and does not allow it to use the Licensed
                    Application on any device that it does not own or control
                    unless agreed by TCRL in writing.&nbsp;</span>
                </li>
              </ol>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol style="margin-bottom: 0in; list-style-type: undefined">
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <span
                  style="font-family: Calibri"
                >The partner hereby agrees that it shall not rent, lease,
                  sell, redistribute, or sublicense the Licensed Application
                  except consent is granted by TCRL. The Partner agrees that it
                  shall not:</span>
              </li>
            </ol>
          </div>
          <ol
            class="decimal_type"
            start="1"
            style="list-style-type: lower-alpha; margin-left: 0.25in"
          >
            <li>
              <span
                style="font-family: Calibri"
              >use (including making any copies of) the Software or
                Documentation beyond the scope of the license granted under this
                Agreement,</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >without providing notice to TCRL, provide any other person
                (legal or natural), including any subcontractor, independent
                contractor, affiliate, employee, agent or service provider of
                Partner, with access to or use of the Software or
                Documentation;&nbsp;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >modify, translate, adapt, or otherwise create derivative works
                of the Software or Documentation or any part
                thereof;&nbsp;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >combine the Software or any part thereof with, or incorporate
                the Software or any part thereof in, any other
                programs;&nbsp;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >reverse engineer, disassemble, decompile, decode or in any
                other manner attempt to derive or gain access to the source code
                of any component of the Software or any part thereof for any
                purpose or any portion thereof and on all portions contained in
                or merged into other programs and/or the Documentation. Partner
                shall notify TCRL if Partner becomes aware of any person or
                entity attempting to reverse engineer, reverse compile, or
                disassemble any of the Products;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >remove, delete, alter, or obscure any trademarks or any
                copyright, trademark, patent, or other intellectual property or
                proprietary rights notices provided on or with the Software or
                Documentation, including any copy thereof;&nbsp;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >rent, lease, lend, sell, sublicense, assign, distribute,
                publish, transfer, or otherwise make available the Software, or
                any features or functionality of the Software, to any Third
                Party for any reason, whether or not over a network or on a
                hosted basis, including in connection with the internet or any
                web hosting, wide area network (WAN), a virtual private network
                (VPN), virtualization, time-sharing, service bureau, software as
                a service, cloud, or other technology or service without
                TCRL&rsquo;s &nbsp;written approval;&nbsp;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >use the Product on operating systems or technology platforms
                other than those designated by TCRL in the
                Documentation;&nbsp;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >use the Software or Documentation in violation of any law,
                regulation, or rule; or&nbsp;</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >use the Software or Documentation for purposes of competitive
                analysis of the Software, the development of a competing
                software product or service, or any other purpose that is to
                TCRL&rsquo;s commercial disadvantage.</span>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  <span
                    style="font-family: Calibri"
                  >&nbsp;The Partner agrees that unless otherwise set forth in
                    the Agreement, any new content features functionality or
                    modules that augment or enhances the product and or any new
                    services(s) shall be subject to this Agreement.</span>
                </li>
              </ol>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined"
          >
            <li>
              <strong><span
                style="font-family: Calibri"
              >APPLICABLE CHARGES.</span></strong>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  <span
                    style="font-family: Calibri"
                  >Subject to the terms and obligations under this Agreement,
                    the Partner agrees to remit 10% of all completed
                    dispatch-hailing, logistics or related requests and
                    transactions from users and customers alike (the
                    <strong>&ldquo;Charges&rdquo;</strong>).</span>
                </li>
                <li>
                  <span
                    style="font-family: Calibri"
                  >Notwithstanding Clause 5.1 above, the Partner agrees that
                    Charges shall be automatically removed and withheld by TCRL
                    at every Card payment processed on the Boxstop
                    Application.</span>
                </li>
                <li>
                  <span
                    style="font-family: Calibri"
                  >The partner agrees that the Charges may be revised by TCRL
                    and appropriately notified of any changes from time to time
                    at its provided email address.&nbsp;</span>
                </li>
                <li>
                  <span
                    style="font-family: Calibri"
                  >Notwithstanding Clause 5.1 above, TCRL reserves the right
                    to issue waivers on the Charges in favour of the Partner.
                    This waiver shall not be enforceable in any form by the
                    Partner.</span>
                </li>
              </ol>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol style="margin-bottom: 0in; list-style-type: undefined">
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <strong><span style="font-family: Calibri">TAX</span></strong>
              </li>
            </ol>
          </div>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0.25in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            TCRL and the Partner agree that it shall bare individual
            responsibilities for all fiscal and tax obligations ensuing from
            their business operations and activities.
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0.25in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined"
          >
            <li>
              <strong><span style="font-family: Calibri">WARRANTIES.</span></strong>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li><strong>TCRL warrants as follows:</strong></li>
              </ol>
            </li>
            <!--  -->
          </ol>
          <ol
            class="decimal_type"
            start="1"
            style="list-style-type: lower-alpha; margin-left: 0.25in"
          >
            <li>
              that the Boxstop application is free from significant defects in
              material and workmanship under normal use;
            </li>
            <li>
              that any update thereto will perform substantially and in
              accordance with the corresponding published specifications;
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  TCRL does not make any warranties, express or implied,
                  including any implied warranties of merchantability and
                  fitness for purpose with respect to the product and all
                  information supplied to you pursuant to this Agreement.
                </li>
                <li>
                  TCRL does not make any warranty as to accuracy or completeness
                  nor is it responsible for errors and omissions that does not
                  represent the use of the Boxstop application as stipulated
                  under this Agreement .
                </li>
                <li>
                  TCRL disclaims any and all liability, including any express or
                  implied warranties whether oral or written for any and all
                  third-party applications and or third-party services.
                </li>
                <li>
                  <strong><span
                    style="font-family: Calibri"
                  >The Partner warrants that:</span></strong>
                </li>
              </ol>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >It is duly authorized under the laws in force in Nigeria, to
                engage in the activities of dispatch-hailing, logistics and
                other related services.</span>
            </li>
            <li>
              <span
                style="font-family: Calibri"
              >That by consenting to this agreement, it is not in breach of
                any Law or third-party obligation and term.</span>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.75in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol style="margin-bottom: 0in; list-style-type: undefined">
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <strong><span
                  style="font-family: Calibri"
                >THE MOBILE PLATFORMS.</span></strong>
              </li>
            </ol>
          </div>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            The Boxstop Application shall only be used on a permitted mobile
            platform (&ldquo;<strong>Mobile</strong>
            <strong>Platform</strong>&rdquo;). The approved Mobile Platforms are
            Apple Inc.&rsquo;s (&ldquo;<strong>Apple</strong>&rdquo;) iOS and
            Google Inc.&rsquo;s (&ldquo;<strong>Google</strong>&rdquo;) Android.
            If you use the Boxstop Application on a Mobile Device running iOS,
            then the following terms and conditions apply. You shall only use
            the Boxstop Application on your Mobile Device as such Mobile Device
            is permitted to be used by the Usage Rules set forth in the Apple
            App Store Terms of Service. TCRL, not Apple, is solely responsible
            for the Boxstop Application and any information or content received
            through the Boxstop &nbsp;Application or the services accessible
            through the Boxstop Application. If you use the Boxstop
            &nbsp;Application on a Mobile Device running Android, then the
            following terms and conditions apply. Notwithstanding anything to
            the contrary in this Agreement, if you use the Boxstop Application
            on a Mobile Device running Android, each User hereby acknowledges
            and agrees that
          </p>
          <ol
            start="1"
            style="list-style-type: lower-alpha"
          >
            <li>
              The Boxstop Application relies in part on functionality provided
              by Google (the &ldquo;Google Functionality&rdquo;),
            </li>
            <li>
              any information provided by such User via the Boxstop Application
              may be shared with Google in connection with such User&rsquo;s use
              of the Google Functionality and
            </li>
            <li>
              all such information shall be handled by Google in accordance with
              the then current Google Privacy Policy (currently available at
              <a
                href="http://www.google.com/policies/privacy"
              >http://www.google.com/policies/privacy</a>
              ), as may be amended by Google from time to time).
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined"
          >
            <li>
              <strong><span
                style="font-family: Calibri"
              >INTELLECTUAL PROPERTY AND PROPRIETARY RIGHTS.</span></strong>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  Partner shall use commercially reasonable efforts to safeguard
                  the Boxstop Application, together with its updates and other
                  related application (including all copies thereof) from
                  infringement, misappropriation, theft, misuse, or unauthorized
                  access. Partner shall promptly notify TCRL if Partner becomes
                  aware of any infringement of the TCRL&rsquo;s Intellectual
                  Property Rights in the Software and fully cooperate with TCRL,
                  at TCRL&rsquo;s sole expense, in any legal action taken by
                  TCRL to enforce its Intellectual Property Rights.
                </li>
                <li>
                  This Agreement shall not be considered to be a sale of any
                  Intellectual Property Rights or other proprietary interests
                  embodied in the Products or Product component thereof (or any
                  copy, derivative work, upgrade, Update, improvement or
                  modification thereof), and nothing herein shall be deemed to
                  establish or imply that the license granted is a conveyance of
                  any underlying Intellectual Property Rights to the Products.
                </li>
                <li>
                  All right, title, and interest in and to the Product and
                  Documentation and all Intellectual Property Rights arising out
                  of or relating to the Product and Documentation, in whole and
                  in part and all copies thereof, are, and shall remain, the
                  sole and exclusive property of TCRL, TCRL Affiliates, and/or
                  its licensors, except as expressly granted to the Partner in
                  this Agreement. TCRL hereby reserves all rights not expressly
                  granted to Partner.
                </li>
                <li>
                  The source code of the Software is a confidential trade secret
                  of TCRL.
                </li>
                <li>
                  TCRL hereby reserves all rights not expressly granted to
                  Partner. Each Free Software Component is the copyright of its
                  respective copyright owner. Only those terms and conditions
                  specified for, or applicable to, each specific Free Software
                  Component pursuant to its applicable governing license shall
                  be applicable to such Free Software Component.
                </li>
              </ol>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined; margin-left: 3.5px"
          >
            <li>
              <strong>SUPPORT.</strong>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  The license granted hereunder shall entitle the Partner to
                  reasonable support services, enquiries and interventions. Such
                  support enquiries and requests shall be directed by email to
                  <a href="mailto:info@boxstopapp.com">info@boxstopapp.com</a>.
                </li>
                <li>
                  Notwithstanding the above provision, this Agreement does not
                  entitle Partner to any Software maintenance or Support
                  Services unless the Partner purchases such maintenance and
                  Support Services from TCRL or an Authorized Partner.
                </li>
                <li>
                  written for any and all third-party applications and or
                  third-party services.
                </li>
                <li>
                  You acknowledge that no representation has been made by TCRL
                  as to the fitness of any third-party application or services
                  for your intended purpose.
                </li>
              </ol>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined; margin-left: 12.5px"
          >
            <li>
              <strong>TERM AND TERMINATION</strong>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  This Agreement and the license granted hereunder shall remain
                  in effect for a term of 1 year preceding the grant of license
                  to the User/Partner (the &ldquo;<strong>Term</strong>&rdquo;).
                </li>
                <li>Either party may terminate this AGREEMENT if:</li>
              </ol>
            </li>
          </ol>
          <ol
            class="decimal_type"
            start="1"
            style="list-style-type: lower-alpha"
          >
            <li>
              the other party is notified in writing that it is in material
              breach of the terms or conditions of this AGREEMENT; and
            </li>
            <li>
              such other party fails to remedy such breach within thirty (30)
              days following such notice.
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>Either party may terminate this AGREEMENT if:</li>
              </ol>
            </li>
            <li>
              the other party files or has filed against it a petition for
              voluntary or involuntary bankruptcy or pursuant to any other
              insolvency law, or is adjudicated bankrupt; and
            </li>
            <li>
              makes or seeks to make a general assignment for the benefit of its
              creditors or applies for, or consents to, the appointment of a
              trustee, received, or custodian for a substantial part of its
              property.
            </li>
            <li>
              Is in breach of a law in force in Nigeria or its operational
              jurisdiction, or in breach of a third party obligation which makes
              the performance of his/her/their obligations under this AGREEMENT
              impracticable.
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  In the event of termination of this AGREEMENT for a material
                  breach by Partner and in addition to all other rights and
                  obligations each party may have under this AGREEMENT:
                </li>
              </ol>
            </li>
            <li>
              the rights and licenses granted to the Products pursuant to this
              AGREEMENT shall automatically terminate; and
            </li>
            <li>
              Partner shall, within thirty (30) days, uninstall and cease to use
              (including purging from any system or storage media) all items in
              its possession proprietary to TCRL, including but not limited to
              all Products, and upon request by TCRL, an authorized
              representative of Partner shall certify in writing to TCRL that
              the Boxsapp application and other confidential information of TCRL
              have been destroyed and or returned
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  Upon expiration or termination of this AGREEMENT for any other
                  reason, the License granted hereunder for any term license
                  shall terminate and Partner shall cease using and destroy all
                  copies of the Software and Documentation.
                </li>
                <li>
                  No expiration or termination shall affect Partner&rsquo;s
                  obligation to pay all Partner Fees that may have become due
                  before such expiration or termination. Each party shall:
                </li>
              </ol>
            </li>
            <li>
              return to the other party all documents and tangible materials
              (and any copies) containing, reflecting, incorporating or based on
              the other party&rsquo;s Confidential Information; and
            </li>
            <li>
              permanently erase all of the other party&rsquo;s Confidential
              Information from its computer systems.
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>&nbsp;</strong>
          </p>
          <div
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            <ol
              style="
                margin-bottom: 0in;
                list-style-type: undefined;
                margin-left: 12.5px;
              "
            >
              <li
                style="
                  margin-top: 0in;
                  margin-right: 0in;
                  margin-bottom: 8pt;
                  margin-left: 0in;
                  line-height: 107%;
                  font-size: 15px;
                  font-family: 'Calibri', sans-serif;
                "
              >
                <strong>INDEMNIFICATION</strong>
              </li>
            </ol>
          </div>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            The Partner agrees to defend, indemnify and hold harmless TCRL and
            its affiliates, independent contractors, service providers,
            suppliers, partners, resellers, distributors, and consultants, and
            their respective directors, officers, employees and agents from and
            against any third-party claims, suits or actions and any resulting
            damages, costs, liabilities and expenses (including, but not limited
            to, reasonable attorneys&apos; fees) arising out of or related to:
          </p>
          <ol
            start="1"
            style="list-style-type: lower-alpha"
          >
            <li>The use of, or inability to use the Software; or</li>
            <li>The violation of any terms of this Agreement.</li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>&nbsp;</strong>
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined; margin-left: 12.5px"
          >
            <li>
              <strong>GOVERNING LAW</strong>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  Unless expressly prohibited by local law, this Agreement is
                  governed by the laws of Nigeria without regard to any conflict
                  of law principles to the contrary. You hereby irrevocably
                  consent to the jurisdiction of the state and federal courts
                  located in Nigeria with respect to any proceeding regarding
                  this Agreement or the Boxstop Application.
                </li>
              </ol>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined"
          >
            <li>
              <strong>COMMUNICATIONS AND NOTIFICATION</strong>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  The partner may contact TCRL in writing, or send notification
                  where required under this Agreement to TCRL by e-mail:
                  <a href="mailto:info@boxstopapp.com">info@boxstopapp.com</a>.
                  TCRL will confirm receipt of this by contacting the Partner in
                  writing, at the earliest time by e-mail.
                </li>
              </ol>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined; margin-left: 12.5px"
          >
            <li>
              <strong>GENERAL TERMS</strong>
              <ol
                class="decimal_type"
                style="list-style-type: undefined"
              >
                <li>
                  Any information obtained by the Partner in terms of, or
                  arising from the implementation of the Partnership Agreement
                  shall be treated as confidential by the Partner and shall not
                  be divulged or permitted to be divulged to any person not
                  being a party to the Agreement, without the prior written
                  consent of the TCRL.
                </li>
              </ol>
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 0in;
              margin-left: 0.5in;
              line-height: 107%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
            "
          >
            &nbsp;
          </p>
          <ol
            class="decimal_type"
            style="list-style-type: undefined"
          >
            <li>
              If any provision of this Agreement is held by a court of competent
              jurisdiction to be invalid, illegal, or unenforceable, the
              remainder of this Agreement will remain in full force and effect,
              and the remaining provisions will be amended to achieve as closely
              as possible the effect of the original term and all other
              provisions of this Agreement will continue in full force.
            </li>
            <li>
              The Partner agrees not to assign or otherwise transfer this
              Agreement, or assign, sublicense or otherwise transfer any of your
              rights under this Agreement without the prior written consent of
              TCRL, and any attempted assignment without such consent will be
              void.
            </li>
            <li>
              This Agreement, TCRL&rsquo;s Privacy Policy and any amendments or
              related agreement issued by TCRL to govern the use and operation
              of the Boxstop application sets forth the entire agreement with
              respect to the Boxstop application and supersedes all prior and
              contemporaneous understandings and agreements with respect to the
              Boxstop application whether written or oral.
            </li>
          </ol>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            &nbsp;
          </p>
          <p
            style="
              margin-top: 0in;
              margin-right: 0in;
              margin-bottom: 8pt;
              margin-left: 0in;
              line-height: 150%;
              font-size: 15px;
              font-family: 'Calibri', sans-serif;
              text-align: justify;
            "
          >
            <strong>If you have any questions regarding the PARTNERSHIP AGREEMENT or
              your use of the Boxstop application, kindly send your enquiries
              to&nbsp;</strong><a
              href="mailto:info@boxstopapp.com"
            ><strong>info@boxstopapp.com</strong></a><strong>&nbsp;</strong>
          </p>
        </div>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BModal,
  BCardTitle,
  BCardText,
  BOverlay,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { apiUrl } from '@/constants/config'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    // validations
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      userName: '',
      userBusinessName: '',
      userPhone: '',
      userEmail: '',
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/pages/boxstop-hero-illustration-01.png'),
      // validation
      required,
      email,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      showRegisterOverlay: false,
      hasError: false,
      errors: {
        message: '',
        data: [],
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        this.sideImg = require('@/assets/images/pages/boxstop-hero-illustration-01.png')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.hasError = false
      this.showRegisterOverlay = true
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          axios({
            url: `${apiUrl}register`,
            data: {
              name: this.userName,
              business_name: this.userBusinessName,
              phone: this.userPhone,
              email: this.userEmail,
              password: this.password,
              c_password: this.cPassword,
            },
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
          })
            .then(response => {
              // console.log(response.data.message);
              this.$toast({
                component: ToastificationContent,
                props: {
                  // title: "Success: " + response.data.message,
                  title:
                    'Success: Partner registered successfully. Please login to verify your account.',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.$router.replace({ path: '/verify-account' })
            })
            .catch(error => {
              this.hasError = true
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Error: ${this.parseError(error.response)}`,
                  /* (error.response.status == 401
                      ? "Unauthenticated"
                      : error.response.status == 404
                      ? "Invalid " + (this.usePhone ? "Phone" : "Email")
                      : error.response.status == 422
                      ? error.response.data.message
                      : error.message) */ icon: 'EditIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.showRegisterOverlay = false
            })
        } else {
          this.showRegisterOverlay = false
        }
      })
    },
    parseError(response) {
      // console.log(response);
      if (response.status == 404) {
        console.log(response.data)
        this.errors.message = response.data.message
        this.errors.data = response.data.data

        return response.data.message
      }
      return 'this is an error'
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
